import React from "react"
import { Image } from "../Images"
import styled from "styled-components"
import { color } from "../Styles"
import { Link } from "gatsby"

const Member = ({ data }) => {
  return (
    <StyledMember>
      <div className="flip-card">
        <div className="face">
          <Image src={data.img} style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "contain" }} />
        </div>
        <div className="back">
          <div className="back-content">
            <div className=" title">
              <p className="nome">{data.title}</p>
              <p className="titulo">{data.text}</p>
            </div>
            <Link className="button" to={data.link}>
              {data.button}
            </Link>
          </div>
        </div>
      </div>
    </StyledMember>
  )
}

export default Member

const StyledMember = styled.div`
  
  .flip-card{
    position: relative;
    width: 100%;
    height: fit-content;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(183, 169, 154, 0.2);
  }
  :hover .flip-card{
    transform: rotateY(180deg);
  }
  .face, .back{
    position: absolute;
    width: 100%;
    height: fit-content;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateY(0deg)
  }
  .face{
    position: relative;
  }
  .back{
    background-color: #fff;
    transform: rotateY(180deg);
    top: 0;
    height: 100%;
    .smallUnderline{
      width: 2em;
      height: 0.25em;
      background-color: black;
      position: absolute;
      bottom: calc(7% + 20px);
      left: calc(20px + 10%);
      z-index: 2;
    }
  }
  .back-content{
    position: absolute;
    background-color: ${color.yellow};
    height: 100%;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title{
      position: relative;
      padding: calc(10% + 0px);
      text-align: center;

      @media only screen and (max-width: 1200px){
        margin-bottom: 1em;
      }
      
      .nome{
        color: #fff;
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-bottom: 0.2em;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      .titulo{
        color: #fff;
        font-size: calc(10px + 3 * (100vw - 769px) / 1151);
        font-weight: 500;
        @media only screen and (min-width: 1920px){
          font-size: 13px;
        }
        @media only screen and (max-width: 768px){
          font-size: calc(12px + 2 * (100vw - 320px) / 448);
        }
        letter-spacing: 0.1em;
      }
    }

    .button {
          display: block;
          width:fit-content;
          text-decoration: none;
          color: ${color.yellow};
          background: ${color.white}; 
          border:0;
          padding: 1rem clamp(15px, 5vw, 35px);
          letter-spacing: 1px;
          text-align: center;
          position: relative;
          transition: all .35s;
          cursor:pointer;
          font-weight:600;
          text-transform:uppercase;
          margin-top:1rem;
          border-radius: 3px;
          margin: 0 auto;
          border: 1px solid #F2AF00;
          margin-top: 3%;

          :hover {
              background-color: #000;
              color: ${color.yellow};
          }
    }

    .especialidades{
      color: #fff;
      font-size: calc(12px + 4 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px){
        font-size: 16px;
      }
      @media only screen and (max-width: 768px){
        font-size: calc(14px + 5 * (100vw - 320px) / 448);
      }
      letter-spacing: 0.1em;
      line-height: 1.6em;
      margin: 0.6em 0;
    }
  }
`