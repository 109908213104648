import React from "react";
import Layout from '../components/Layout';
import { useTranslation } from "react-i18next"
import { graphql } from 'gatsby';
import styled from "styled-components";
import { media } from "../components/Styles"
import TeamCard from "../components/list/teamCard"
import TitleBanner from "../components/TitleBanner/TitleBanner";

const ServicesPage = () => {
    const { t } = useTranslation()

    return (
        <Layout>
            <ProjectsStyled>
                <TitleBanner
                    image='header-footer/imagens-banner/banner-servicos.png'
                    alt='Enomac'
                    logo
                    data={t("services", { returnObjects: true }).service}
                />
                <div className="Projects">
                    {t("services", { returnObjects: true }).servicesCards.serviceBanner.map((data, i) => {
                        return (
                            <TeamCard data={data} key={"TeamMember" + i} />
                        )
                    })}
                </div>
            </ProjectsStyled>
        </Layout>
    );
};

export default ServicesPage;

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "services"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
const ProjectsStyled = styled.div`
.Projects {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 3rem 4%;
    max-width: 1080px;
    margin: 0 auto;
    margin-top: -5rem;
    margin-bottom: 5rem;

    ${media.s`
        grid-template-columns: repeat(1,1fr);
        width: 80%;
    `}
}

`